const logotext = "Rick";
const meta = {
    title: "Rick Feng",
    description: "I’m Rick Feng",
};

const introdata = {
    title: "I’m Rick Feng",
    animated: {
        first: "I love Data Science",
        second: "I love Dad Jokes",
        third: "I love ML and AI",
    },
    description: "I am a Data scientist with AI/ML and data analysis experience across multiple business domains",
};

const dataabout = {
    title: "A bit about myself",
};
const worktimeline = [{
        jobtitle: "Full Stack Developer/Scrum Master @ ResearchTube",
        start: "April 2021",
        end: "Sept 2021",
        desc: "",
    },

];

const skills = [{
        name: "Python",
        value: 100,
    },
    {
        name: "Pytorch",
        value: 80,
    },
    {
        name: "Javascript",
        value: 80,
    },
    {
        name: "Django",
        value: 85,
    },
    {
        name: "Pandas",
        value: 88,
    },
    {
        name: "NodeJS",
        value: 90,
    },
    {
        name: "CUDA",
        value: 76,
    },
];

const dataportfolio = [{
        img: "/image/projects/seq2seq.png",
        desctiption: "Deep-Learning-Models-for-Structured-Data",
        link: "https://github.com/Rick-Feng-u/Deep-Learning-Models-for-Structured-Data",
        desc: "a lightweight and highly efficient deep learning model build with Pytorch for accurate classification of various XML documents with vastly different structures.",
    },
    {
        img: "/image/projects/seq2seq.png",
        desctiption: "Data Analyses of Online Courses",
        link: "https://github.com/HCI-Data-Analysis/HCI-data-analysis",
        desc: "Data analyses on raw data collected with a total of over 2 million different data points from studentsfor investigation of the effectiveness of asynchronous online learning",
    },
];

const contactConfig = {
    YOUR_EMAIL: "rickfeng99@gmail.com",
    YOUR_FONE: "(778)882-0912",
    description: "",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_id",
    YOUR_TEMPLATE_ID: "template_id",
    YOUR_USER_ID: "user_id",
};

const socialprofils = {
    github: "https://github.com/Rick-Feng-u",
    facebook: "https://www.facebook.com/rick.feng.18",
    linkedin: "https://www.linkedin.com/in/rick-feng/",
    twitter: "https://twitter.com/rickfeng99",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};
